import logo from './nw-logo.png';
import settings from './settings.png';
import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { useEffect, useState } from 'react';
import ConfettiExplosion from "react-confetti-explosion";

const bigExplodeProps = {
  force: 0.6,
  duration: 5000,
  particleCount: 200,
  floorheight: 1600,
  floorwidth: 1600
};

const LAMBDA_URL = "https://hkc26ljlah2vfthsygkqxygpeq0hebkr.lambda-url.eu-west-2.on.aws/";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/health" element={<Health />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function Home() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [explode, setExplode] = useState(false);

  const triggerConfetti = () => {
    if (!explode) {
      setExplode(true);
      setTimeout(() => setExplode(false), 4000);
    }
  };

  const fetchData = async () => {
    setLoading(true);

    fetch(LAMBDA_URL)
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
        triggerConfetti();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    document.title = "Mortgage Rates";
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.matches('.SettingsButton')) {
        setShowSettings(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="content">

      <div className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <img src={settings} className={`SettingsButton ${showSettings ? 'Active' : ''}`}  onClick={() => setShowSettings(!showSettings)} alt="settings"/>
      </div>

      {showSettings && 
        <div className="SettingsMenu show">
          <div className="Setting" onClick={fetchData}>Reset data</div>
          <div className="Setting" onClick={triggerConfetti}>Confetti!</div>
        </div>
      }

      <div className="App-body">
        <div className="Title">Current Mortgage Rate</div>

        {loading ? (
          <div className="Pre-result">
            Contacting database...
          </div>
        ) : (
          <div className="Result">
            { 
              data && data.rates && data.rates.length > 0 ? (
                <div className="Rate">{parseFloat(data.rates[0].rate).toFixed(2)}%</div>
              ) : (
                <div className="Rate">No data :(</div>
              )
            }

            {explode ? (
              <ConfettiExplosion {...bigExplodeProps} />
            ) : (
              <div />
            )}

            <table className="Data-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Rate (%)</th>
                </tr>
              </thead>

              <tbody>
                {
                  data && data.rates && data.rates.length > 0 ? (
                    data.rates.map(data => {
                      const date = new Date(data.time);
                      const day = String(date.getDate()).padStart(2, '0');
                      const month = String(date.getMonth() + 1).padStart(2, '0'); 
                      const year = date.getFullYear();
                      const hours = String(date.getHours()).padStart(2, '0');
                      const minutes = String(date.getMinutes()).padStart(2, '0');

                      const formattedDate = `${hours}:${minutes} ${day}/${month}/${year}`;

                      return (
                        <tr key={data.id}>
                          <td>{formattedDate}</td>
                          <td>{parseFloat(data.rate).toFixed(2)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="2">No data</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

function Health() {
  return (
    <p>status: 200, message: App OK</p>
  );
}

export default App;
